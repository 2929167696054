import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Viewport from "../components/Viewport"

const Terms = () => (
  <Layout>
    <Seo title="Terms of Service" />

    <section className="terms">
      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h1>Terms of Service</h1>
          <p>
            <span>Last updated: Apr 1, 2024</span>
          </p>

          <p>
            Please read these Terms of Service ("Terms", "Terms of Service")
            carefully before using the Emerald website and/or downloaded
            applications ("Website", "Software" or collectively "Services")
            operated by EmeraldPay, Ltd ("Company", "us", "we", or "our").
          </p>

          <p>
            Your access to and use of the Services is conditioned on your
            acceptance of and compliance with these Terms. These Terms apply to
            all visitors, users and others who access or use the Services.
          </p>

          <p>
            By accessing or using the Services you agree to be bound by these
            Terms. If you disagree with any part of the terms then you may not
            access the Services.
          </p>
        </article>
      </Viewport>

      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h2>Links To Other Web Sites</h2>

          <p>
            Our Website may contain links to third-party web sites or services
            that are not owned or controlled by Company.
          </p>

          <p>
            Company has no control over, and assumes no responsibility for, the
            content, privacy policies, or practices of any third party web sites
            or services. You further acknowledge and agree that Company shall
            not be responsible or liable, directly or indirectly, for any damage
            or loss caused or alleged to be caused by or in connection with use
            of or reliance on any such content, goods or services available on
            or through any such web sites or services.
          </p>

          <p>
            We strongly advise you to read the terms and conditions and privacy
            policies of any third-party web sites or services that you visit.
          </p>
        </article>
      </Viewport>

      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h2>Third Party Services and Content</h2>

          <p>
            In using our Services, you may view content or utilize services
            provided by third parties, including links to web pages and services
            of such parties ("Third-Party Content"). We do not control, endorse
            or adopt any Third-Party Content and will have no responsibility for
            Third-Party Content, including, without limitation, material that
            may be misleading, incomplete, erroneous, offensive, indecent or
            otherwise objectionable in your jurisdiction. In addition, your
            business dealings or correspondence with such third parties are
            solely between you and the third parties. We are not responsible or
            liable for any loss or damage of any sort incurred as the result of
            any such dealings, and you understand that your use of Third-Party
            Content, and your interactions with third parties, is at your own
            risk.
          </p>
        </article>
      </Viewport>

      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h2>Cookies</h2>
          <p>
            We employ the use of cookies. By using Website you consent to the
            use of cookies. Most of the modern day interactive web sites use
            cookies to enable us to retrieve user details for each visit.
            Cookies are used in some areas of our sit eto enable the
            functionality of this area and ease of use for those people
            visiting. Some of our affiliate / advertising partners may also use
            cookies.
          </p>
        </article>
      </Viewport>

      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h2>Digital Assets</h2>
          <p>
            Company doesn't have access to and can't recover users Private Keys
            and/or Backup Phrases create or used in Software. Company doesn't
            create, and has no control over, any of Digital Assets you interact
            with through Software. Company is not liable for any loses or
            damages that result from providing you with Software or Services.
          </p>
        </article>
      </Viewport>

      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h2>Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of the United Kingdom, without regard to its conflict of
            law provisions.
          </p>
          <p>
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service, and supersede and replace any prior agreements we might
            have between us regarding the Service.
          </p>
        </article>
      </Viewport>

      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h2>Changes</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will try to
            provide at least 30 days notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </p>
          <p>
            By continuing to access or use our Services after those revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, please stop using the Service.
          </p>
        </article>
      </Viewport>

      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h2>License</h2>
          <p>
            Unless otherwise stated, Company and/or its licensors own the
            intellectual property rights for all material on the Website. All
            intellectual property rights are reserved. You may view and/or print
            pages from https://emerald.cash for your own personal use subject to
            restrictions set in these terms and conditions.
          </p>
          <p>You must not:</p>

          <p>
            <li>
              Republish material from https://emerald.cash or it's subdomains
            </li>
            <li>
              Sell, rent or sub-license material from https://emerald.cash or
              it's subdomains
            </li>
            <li>
              Reproduce, duplicate or copy material from https://emerald.cash or
              it's subdomains
            </li>
          </p>
        </article>
      </Viewport>

      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h2>DISCLAIMER OF WARRANTIES</h2>
          <p>
            (a) TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, AND EXCEPT
            AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY US, OUR
            SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE
            MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. WE EXPRESSLY
            DISCLAIM, AND YOU WAIVE, ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
            OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
            NON-INFRINGEMENT AS TO OUR SERVICES, INCLUDING THE INFORMATION,
            CONTENT AND MATERIALS CONTAINED THEREIN.
          </p>
          <p>
            (b) YOU ACKNOWLEDGE THAT INFORMATION YOU STORE OR TRANSFER THROUGH
            OUR SERVICES MAY BECOME IRRETRIEVABLY LOST OR CORRUPTED OR
            TEMPORARILY UNAVAILABLE DUE TO A VARIETY OF CAUSES, INCLUDING
            SOFTWARE FAILURES, PROTOCOL CHANGES BY THIRD PARTY PROVIDERS,
            INTERNET OUTAGES, FORCE MAJEURE EVENT OR OTHER DISASTERS, SCHEDULED
            OR UNSCHEDULED MAINTENANCE, OR OTHER CAUSES EITHER WITHIN OR OUTSIDE
            OUR CONTROL. YOU ARE SOLELY RESPONSIBLE FOR BACKING UP AND
            MAINTAINING DUPLICATE COPIES OF ANY INFORMATION YOU STORE OR
            TRANSFER THROUGH OUR SERVICES.
          </p>
        </article>
      </Viewport>

      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h2>LIMITATION OF LIABILITY</h2>
          <p>
            EXCEPT AS OTHERWISE REQUIRED BY LAW, IN NO EVENT SHALL COMPANY, OUR
            DIRECTORS, MEMBERS, EMPLOYEES OR AGENTS BE LIABLE FOR ANY SPECIAL,
            INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND,
            INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF
            DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT
            LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY
            CONNECTED WITH THE USE OF OR INABILITY TO USE OUR SERVICES OR THE
            COMPANY MATERIALS, INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED
            BY OR RESULTING FROM RELIANCE BY ANY USER ON ANY INFORMATION
            OBTAINED FROM COMPANY, OR THAT RESULT FROM MISTAKES, OMISSIONS,
            INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES,
            DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE,
            WHETHER OR NOT RESULTING FROM A FORCE MAJEURE EVENT, COMMUNICATIONS
            FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO COMPANY?S
            RECORDS, PROGRAMS OR SERVICES.
          </p>
        </article>
      </Viewport>

      <Viewport rootMargin="0px 0px -200px 0px">
        <article>
          <h2>Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us.</p>
        </article>
      </Viewport>
    </section>
  </Layout>
)

export default Terms
